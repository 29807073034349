const el = require('../../../lib/utils/jsx_component');
module.exports = renderObj => {
  const {
    firstBaseRunnerFlag,
    secondBaseRunnerFlag,
    thirdBaseRunnerFlag
  } = renderObj;
  const color1 = firstBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  const color2 = secondBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  const color3 = thirdBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  return el("div", null, el("svg", {
    width: "22",
    height: "16",
    viewBox: "0 0 22 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, el("rect", {
    x: "6.27594",
    y: "4.82018",
    width: "6.70826",
    height: "6.70826",
    transform: "rotate(-45.826 6.27594 4.82018)",
    fill: `${color3}`
  }), el("rect", {
    x: "0.134979",
    y: "10.4241",
    width: "6.70826",
    height: "6.70826",
    transform: "rotate(-45.826 0.134979 10.4241)",
    fill: `${color2}`
  }), el("rect", {
    x: "12.2736",
    y: "10.4939",
    width: "6.70826",
    height: "6.70826",
    transform: "rotate(-45.826 12.2736 10.4939)",
    fill: `${color1}`
  })));
};