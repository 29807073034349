import Glide from '@glidejs/glide'
import yssHorizontalMLB from '../../renderers/yss_horizontal_mlb'
import yssVerticalMLB from '../../renderers/yss_vertical_mlb'
import { rapidReady, ylkStringToObj } from '../../../../lib/utils/rapid'
import { Constants, errorLogger } from '../../../../lib/ads/utils'

const SCORES_TITLE = '.scores-title'
const SCORES_MENU_SELECTED = '.scores-menu .selected'
const SCORE_SLIDE_DATA_SPORT = '.score-slide[data-sport]'
const GLIDE = '.glide'
const GLIDE_ARROW_LEFT = '.glide__arrow--left'
const GLIDE_ARROW_RIGHT = '.glide__arrow--right'

const GLIDE_ARROW_LEFT_V2_H = '.m-yss__h .glide__arrow--left'
const GLIDE_ARROW_RIGHT_V2_H = '.m-yss__h .glide__arrow--right'

const VERTICAL_TYPE = 'vertical'
const HORIZONTAL_TYPE = 'horizontal'
const GAME_STATUS_IN_PROGRESS = 'IN_PROGRESS'
const GAME_STATUS_FINAL_CLASS_H = 'game-status-final'
const GAME_STATUS_FINAL_CLASS_V = 'm-yss__vs__game-status-final'
const GAME_STATUS_NOT_FINAL_CLASS = 'game-status-not-final'
const GAME_STATUS_NOT_FINAL = `.${GAME_STATUS_NOT_FINAL_CLASS}`

const SELECTED_OPTION_ID_V2 = 'm-yss__vs__list-id'
const SELECTED_OPTION_CLASS_V2 = 'm-yss__vs__dropdown_selected'
const SPORTS_LIST_V2 = '.m-yss__vs--main-list'

const SELECTED_OPTION_ID_V2H = 'm-yss_h_menu__list-id'
const SELECTED_OPTION_CLASS_V2H = 'm-yss_h_menu__dropdown_selected'
const SPORTS_LIST_V2H = '.m-yss__h .score-slide'

const SPORTS_LABEL_V2_H = '.m-yss_h_menu_with_glide__dropdown--list_label'
const SELECTED_OPTION_ID_V2H_WITH_GLIDE = 'm-yss_h_menu_with_glide__list-id'
const SELECTED_OPTION_CLASS_V2H_WITH_GLIDE = 'm-yss_h_menu_with_glide__dropdown_selected'

const LEAGUE_FALLBACK_ID_V2 = 'm-yss__vs_league__fallback'

const sportContainerElems = [
  ...document.querySelectorAll('.m-yss__vs'),
  ...document.querySelectorAll('.m-yss_h_menu'),
  ...document.querySelectorAll('.m-yss__h-scores-container')
]

const DELAY_IN_SECONDS = 90
const WEEK_DAY = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const IS_HORIZONTAL = !!document.querySelector('.m-yss__h')
const IS_VERTICAL = !!document.querySelector('.m-yss__vs')

const MLB_LEAGUE = 'mlb'
const NFL_LEAGUE = 'nfl'
const NBA_LEAGUE = 'nba'
const NCAA_LEAGUE = 'ncaa'
const ALL_LEAGUE = 'all leagues'
const OLYMPIC_LEAGUE = 'olympics'

const getDefaultLeagueForScoreBoard = () => {
  let defaultValue = NBA_LEAGUE
  const tabs = document.querySelectorAll('.m-yss_h_menu__dropdown--list_label')
  if (tabs && tabs.length > 0) {
    defaultValue = tabs[0].dataset.value
  }
  return defaultValue
}

const SLIDER_OPTION = {
  type: 'slider',
  hoverpause: false,
  bound: false,
  rewind: false,
  peak: 50,
  autoplay: false,
  perView: 8,
  gap: 0,
  startAt: 0,
  animationDuration: 300,
  perSwipe: '|',
  breakpoints: {
    1080: { perView: 5 },
    850: { perView: 4 },
    752: { perView: 6 },
    600: { perView: 4 },
    400: { perView: 3 },
    300: { perView: 2 },
    200: { perView: 1 }
  }
}

if (IS_HORIZONTAL) {
  SLIDER_OPTION.perView = 6
  SLIDER_OPTION.breakpoints = {
    1470: { perView: 5 },
    1140: { perView: 4 },
    950: { perView: 3 },
    450: { perView: 2 },
    312: { perView: 1 }
  }
  SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH = 68
  SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH = 162
  SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH = 144
  SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH = 106
  SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH = 106
  SLIDER_OPTION.SCORE_FALLBACK = 'score-fallback'
  SLIDER_OPTION.SPORT_TITLE_WRAP = 'sport-title-wrap'
  SLIDER_OPTION.OLYMPIC_MEDAL_TITLE_WRAP = 'olympic-title-wrap'
  SLIDER_OPTION.LEAGUE_FALLBACK_CLASS = '.league--fallback'
  SLIDER_OPTION.OLYMPIC_SCORE_LEAGUE_WRAP = 'm-yss__h-score-strip-olympic'
  SLIDER_OPTION.CURRENT_SELECTED_LEAGUE = getDefaultLeagueForScoreBoard()
}

const isHorizontalForSportPage = () => {
  const sportMenuElement = !!document.querySelector('.m-yss_h_menu.sports')
  if (sportMenuElement && window.innerWidth <= 600) {
    return true
  }
  return false
}

const isHorizontalForHomePage = () => {
  const sportMenuElement = !!document.querySelector('.m-yss_h_menu.homepage')
  if (sportMenuElement && window.innerWidth >= 601 && window.innerWidth <= 1123) {
    return true
  }
  return false
}

const isLeagueAttachedWithGliderForSportPage = () => {
  const sportMenuElement = !!document.querySelector('.m-yss_h_menu.sports')
  if (sportMenuElement && window.innerWidth > 600) {
    return true
  }
  return false
}

const checkForHorizontalScoreboard = () => isHorizontalForSportPage() || isHorizontalForHomePage()

const setDefaultGradient = () => {
  const sportGradientElements = document.querySelectorAll('.score-wrap.first')
  if (sportGradientElements && sportGradientElements.length > 0) {
    sportGradientElements.forEach(slide => {
      if (slide.classList.contains('disableGradient')) {
        slide.classList.remove('disableGradient')
      }
      slide.classList.add('enableGradient')
    })
  }
}

const setRightArrowGradient = count => {
  const sportGradientElements = document.querySelectorAll('.score-wrap.first')
  let countIndex = 0
  if (sportGradientElements && sportGradientElements.length > 0) {
    sportGradientElements.forEach(slide => {
      if (countIndex < count) {
        if (slide.classList.contains('enableGradient')) {
          slide.classList.remove('enableGradient')
        }
        slide.classList.add('disableGradient')
        countIndex += 1
      } else {
        if (slide.classList.contains('disableGradient')) {
          slide.classList.remove('disableGradient')
        }
        slide.classList.add('enableGradient')
      }
    })
  }
}

const debounceForSportUI = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const _sportsLogError = function(e, level = Constants.ERROR_LEVEL.ERROR) {
  const error = typeof e === 'string' ? new Error(e) : e
  const logger = errorLogger()
  logger(`[Yahoo sport scores ${level}] (controller) ${error?.message || 'Generic Error'}`, error)
}

const isVerticalGameStatusElement = gameId =>
  document.getElementById(`m_yss_vs_game_status_${gameId}`)

const isHorizontalGameStatusElement = gameId =>
  document.getElementById(`m_yss_h_game_status_${gameId}`)

const disabledGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  if (arrow) {
    arrow.style.color = '#C7CDD2'
    arrow.style.cursor = 'none'
    arrow.style.display = 'block'
    if (IS_HORIZONTAL) {
      const arrow_path = arrow.querySelector('svg')
      arrow_path.classList.add('disable_arrow')
      arrow_path.classList.remove('enable_arrow')
    }
    arrow.disabled = true
  }
}

const enabledGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  if (arrow) {
    arrow.style.color = '#0047ff'
    arrow.style.cursor = 'pointer'
    arrow.style.display = 'block'
    if (IS_HORIZONTAL) {
      const arrow_path = arrow.querySelector('svg')
      arrow_path.classList.add('enable_arrow')
      arrow_path.classList.remove('disable_arrow')
    }
    arrow.disabled = false
  }
}

const hideGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  arrow.style.display = 'none'
}

const showGlideArrow = (elem, selector) => {
  const arrow = elem.querySelector(selector)
  arrow.style.display = 'block'
}

const _postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  return response
}

const _formatAMPM = date => {
  let hours = new Date(date).getHours()
  let minutes = new Date(date).getMinutes()
  const AM_PM = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours || 12
  minutes = minutes.toString().padStart(2, '0')
  return `${hours}:${minutes}${AM_PM}`
}

const isToUpdateBasedOnTimeAndModuleView = (firstTimeLoad, startTime) => {
  try {
    if (!sportContainerElems.length) {
      return false
    }
    if (firstTimeLoad) {
      if (startTime) {
        const startTimeInMSec = new Date(startTime).getTime()
        const currTimeInMSec = Date.now()
        if (currTimeInMSec > startTimeInMSec) {
          return true
        }
      }
    }
    if (window?.wafer?.utils !== undefined) {
      const { elementInView } = window.wafer.utils
      if (!elementInView) {
        return false
      }
      const sportModuleInView = sportContainerElems.find(elm =>
        elementInView(elm, {}, wafer.base.viewport)
      )
      if (sportModuleInView) {
        if (startTime) {
          const startTimeInMSec = new Date(startTime).getTime()
          const currTimeInMSec = Date.now()
          if (currTimeInMSec > startTimeInMSec) {
            return true
          }
        }
      }
    }
    return false
  } catch (err) {
    _sportsLogError(err)
    return false
  }
}

const setDateAndTimeForNonFinal = (gameId, startTime) => {
  const getDay = new Date(startTime).getDay()
  const getTime = _formatAMPM(startTime)
  const getDate = new Date(startTime).getDate()
  const currentDate = new Date().getDate()
  if (IS_HORIZONTAL && isHorizontalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText =
      getDate === currentDate ? getTime : `${WEEK_DAY[getDay]} ${getTime}`
  }
  if (IS_VERTICAL && isVerticalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText =
      getDate === currentDate ? getTime : `${WEEK_DAY[getDay]} ${getTime}`
  }
}

const updateMLBSport = (gameId, renderObj, displayName, out, type) => {
  let markup = ''
  if (type === VERTICAL_TYPE && isVerticalGameStatusElement(gameId)) {
    markup = yssVerticalMLB(renderObj)
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).appendChild(markup)
  } else if (type === HORIZONTAL_TYPE && isHorizontalGameStatusElement(gameId)) {
    markup = yssHorizontalMLB(renderObj)
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = `${displayName} - ${out}`
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).appendChild(markup)
  }
}

const updateRealTimeGameUI = (gameId, displayName, type) => {
  const displayNameList = displayName.split(' ')
  if (type === VERTICAL_TYPE && isVerticalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = `${displayNameList?.[1] ||
      ''}`
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
    document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerText = `${displayNameList?.[0] ||
      ''}`
  } else if (type === HORIZONTAL_TYPE && isHorizontalGameStatusElement(gameId)) {
    document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = `${displayNameList?.[1] ||
      ''} ${displayNameList?.[0]}`
    document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
  }
}

const isToUpdateRealTimeGameUI = gameId =>
  gameId.startsWith(NBA_LEAGUE) || gameId.startsWith(NFL_LEAGUE) || gameId.startsWith(NCAA_LEAGUE)

const isToUpdateLeagueOrNot = gameId => {
  const excludedLeagues = []
  for (let index = 0; index < excludedLeagues.length; index++) {
    if (gameId.startsWith(excludedLeagues[index])) {
      return true
    }
  }
  return false
}

const updateUIForNonFinal = sports_game => {
  const {
    awayTeam,
    homeTeam,
    status,
    gameId,
    displayName,
    out,
    firstBaseRunnerFlag,
    secondBaseRunnerFlag,
    thirdBaseRunnerFlag
  } = sports_game

  let onGoingGameFlag = false
  if (status === GAME_STATUS_IN_PROGRESS) {
    onGoingGameFlag = true
  }
  if (homeTeam?.score || homeTeam?.score === 0) {
    if (IS_HORIZONTAL) {
      document.getElementById(`m_yss_h_game_ht_sv_${gameId}`).innerText = homeTeam.score
    }
    if (IS_VERTICAL) {
      document.getElementById(`m_yss_vs_game_ht_sv_${gameId}`).innerText = homeTeam.score
    }
    onGoingGameFlag = true
  }
  if (awayTeam?.score || awayTeam?.score === 0) {
    if (IS_HORIZONTAL) {
      document.getElementById(`m_yss_h_game_at_sv_${gameId}`).innerText = awayTeam.score
    }
    if (IS_VERTICAL) {
      document.getElementById(`m_yss_vs_game_at_sv_${gameId}`).innerText = awayTeam.score
    }
    onGoingGameFlag = true
  }

  if (IS_HORIZONTAL && isHorizontalGameStatusElement(gameId)) {
    const horizontal_game_status =
      status.toLowerCase() !== 'final' ? GAME_STATUS_NOT_FINAL_CLASS : GAME_STATUS_FINAL_CLASS_H

    if (horizontal_game_status === GAME_STATUS_FINAL_CLASS_H) {
      document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerHTML = ''
      document.getElementById(`m_yss_h_game_b_c_${gameId}`).innerText = ''
      document.getElementById(`m_yss_h_game_status_${gameId}`).className = GAME_STATUS_FINAL_CLASS_H
      document.getElementById(`m_yss_h_game_status_${gameId}`).innerText = displayName
      if (awayTeam?.winner) {
        document.getElementById(
          `m_yss_h_game_at_${gameId}`
        ).className = `away-team ${awayTeam.winner}`
      } else {
        document.getElementById(`m_yss_h_game_at_${gameId}`).className = `away-team`
      }
      if (homeTeam?.winner) {
        document.getElementById(
          `m_yss_h_game_ht_${gameId}`
        ).className = `home-team ${homeTeam.winner}`
      } else {
        document.getElementById(`m_yss_h_game_ht_${gameId}`).className = `home-team`
      }
    } else if (onGoingGameFlag) {
      document.getElementById(
        `m_yss_h_game_at_${gameId}`
      ).className = `away-team ${GAME_STATUS_FINAL_CLASS_H} inprogress`
      document.getElementById(
        `m_yss_h_game_ht_${gameId}`
      ).className = `home-team ${GAME_STATUS_FINAL_CLASS_H} inprogress`

      if (gameId.startsWith(MLB_LEAGUE)) {
        const renderObj = {
          firstBaseRunnerFlag,
          secondBaseRunnerFlag,
          thirdBaseRunnerFlag,
          displayName,
          out
        }
        updateMLBSport(gameId, renderObj, displayName, out, HORIZONTAL_TYPE)
      } else if (isToUpdateRealTimeGameUI(gameId)) {
        updateRealTimeGameUI(gameId, displayName, HORIZONTAL_TYPE)
      }
    }
  }
  if (IS_VERTICAL && isVerticalGameStatusElement(gameId)) {
    const vertical_game_status =
      status.toLowerCase() !== 'final' ? GAME_STATUS_NOT_FINAL_CLASS : GAME_STATUS_FINAL_CLASS_V

    if (vertical_game_status === GAME_STATUS_FINAL_CLASS_V) {
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerHTML = ''
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).innerText = ''
      document.getElementById(
        `m_yss_vs_game_status_${gameId}`
      ).className = GAME_STATUS_FINAL_CLASS_V
      document.getElementById(`m_yss_vs_game_status_${gameId}`).innerText = displayName
      if (awayTeam?.winner) {
        document.getElementById(
          `m_yss_vs_game_at_${gameId}`
        ).className = `m-yss__away-team ${awayTeam.winner}`
      } else {
        document.getElementById(`m_yss_vs_game_at_${gameId}`).className = `m-yss__away-team`
      }
      if (homeTeam?.winner) {
        document.getElementById(
          `m_yss_vs_game_ht_${gameId}`
        ).className = `m-yss__home-team ${homeTeam.winner}`
      } else {
        document.getElementById(`m_yss_vs_game_ht_${gameId}`).className = `m-yss__home-team`
      }
    } else if (onGoingGameFlag) {
      document.getElementById(
        `m_yss_vs_game_at_${gameId}`
      ).className = `m-yss__away-team ${GAME_STATUS_FINAL_CLASS_V} inprogress`
      document.getElementById(
        `m_yss_vs_game_ht_${gameId}`
      ).className = `m-yss__home-team ${GAME_STATUS_FINAL_CLASS_V} inprogress`

      document.getElementById(`m_yss_vs_game_status_${gameId}`).classList.add('inprogress')
      document.getElementById(`m_yss_vs_game_b_c_${gameId}`).classList.add('inprogress')

      if (gameId.startsWith(MLB_LEAGUE)) {
        const renderObj = {
          firstBaseRunnerFlag,
          secondBaseRunnerFlag,
          thirdBaseRunnerFlag,
          displayName,
          out
        }
        updateMLBSport(gameId, renderObj, displayName, out, VERTICAL_TYPE)
      } else if (isToUpdateRealTimeGameUI(gameId)) {
        updateRealTimeGameUI(gameId, displayName, VERTICAL_TYPE)
      }
    }
  }
}

const DateAndTimeController = (firstTimeLoad, flag) => {
  const GameStatusElements = document.querySelectorAll(GAME_STATUS_NOT_FINAL)
  const gameIds = []
  GameStatusElements.forEach(game => {
    const {
      dataset: { gameId, startTime }
    } = game
    if (gameId && !gameIds.includes(gameId)) {
      if (
        !isToUpdateLeagueOrNot(gameId) &&
        isToUpdateBasedOnTimeAndModuleView(firstTimeLoad, startTime)
      ) {
        gameIds.push(gameId)
      }
      if (!flag && startTime) setDateAndTimeForNonFinal(gameId, startTime)
    }
  })

  if (gameIds.length > 0) {
    const url = `/nex/modules/yahoo_sports_scores/v1/?isXHR=true&m_mode=json`
    _postData(url, {
      data: gameIds
    })
      .then(response => response.json())
      .then(responseData => {
        const {
          data: { response }
        } = responseData
        response.forEach(sports_game => {
          updateUIForNonFinal(sports_game)
        })
      })
      .catch(err => {
        _sportsLogError(err)
      })
      .finally(() => {
        setTimeout(DateAndTimeController, DELAY_IN_SECONDS * 1000, false, true)
      })
  } else {
    setTimeout(DateAndTimeController, 20 * 1000, firstTimeLoad, true)
  }
}

const customLeagueFallbackWidth = () => {
  SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH = SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH
}

export default class YahooSportsScores {
  constructor({ selector }) {
    DateAndTimeController(true)
    this.defaultLeagueValue = getDefaultLeagueForScoreBoard()
    this.translateWidthValue = 0
    this.eList = this.getDefaultEList()
    this._addEventListeners()
    this.elems = [...document.querySelectorAll(selector)]
    if (!this.elems.length) {
      return
    }
    this.elems.forEach(elem => this.init(elem))
  }

  _addEventListeners() {
    document.addEventListener('click', evt => {
      this.onClickEvent(evt)
    })
    if (IS_VERTICAL) {
      this.applyVerticalDefaultFilters()
    }
    if (IS_HORIZONTAL) {
      this.applyHorizontalDefaultFilters()
    }
  }

  applyDefaultFilters(selectedLabel) {
    if (selectedLabel && selectedLabel?.dataset?.value) {
      this.applyFilters(selectedLabel?.dataset?.value)
    }
  }

  applyHorizontalDefaultFilters() {
    const currSelectedLabel = document.getElementById(SELECTED_OPTION_ID_V2H)
    const currSelectedLabelWithGlider = document.getElementById(SELECTED_OPTION_ID_V2H_WITH_GLIDE)
    if (checkForHorizontalScoreboard()) {
      this.applyDefaultFilters(currSelectedLabel)
    }

    if (isLeagueAttachedWithGliderForSportPage()) {
      this.applyDefaultFilters(currSelectedLabelWithGlider)
    }
  }

  applyVerticalDefaultFilters() {
    const currSelectedLabel = document.getElementById(SELECTED_OPTION_ID_V2)
    this.applyDefaultFilters(currSelectedLabel)
  }

  getLeagueValue(defaultValue) {
    let leagueValue = defaultValue
    if (leagueValue === ALL_LEAGUE) {
      leagueValue = this.defaultLeagueValue
    }
    return leagueValue
  }

  applyFilters(defaultValue) {
    SLIDER_OPTION.CURRENT_SELECTED_LEAGUE = defaultValue
    const vertical_selected_id = document.getElementById(SELECTED_OPTION_ID_V2)
    const horizontal_selected_id = document.getElementById(SELECTED_OPTION_ID_V2H)
    const horizontal_with_glide_selected_id = document.getElementById(
      SELECTED_OPTION_ID_V2H_WITH_GLIDE
    )

    const leagueValue = this.getLeagueValue(defaultValue)
    const leagueText =
      leagueValue === OLYMPIC_LEAGUE
        ? leagueValue.charAt(0).toUpperCase() + leagueValue.slice(1)
        : leagueValue.toUpperCase()
    if (vertical_selected_id) {
      vertical_selected_id.innerText = leagueText
      vertical_selected_id.setAttribute('data-value', leagueValue)
    }
    if (horizontal_selected_id) {
      horizontal_selected_id.innerText = `Scores: ${leagueText}`
      horizontal_selected_id.setAttribute('data-value', leagueValue)
    }
    if (horizontal_with_glide_selected_id) {
      horizontal_with_glide_selected_id.innerText = defaultValue.toUpperCase()
      horizontal_with_glide_selected_id.setAttribute('data-value', defaultValue)

      const menuWithGlideSelectedElements = document.querySelectorAll(
        '.m-yss_h_menu_with_glide--select_tabs'
      )
      if (menuWithGlideSelectedElements && menuWithGlideSelectedElements.length > 0) {
        menuWithGlideSelectedElements.forEach(item => {
          item.setAttribute('data-sport', defaultValue)
        })
      }

      const menuSelectedElements = document.querySelectorAll('.m-yss_h_menu--select_tabs')
      if (menuSelectedElements && menuSelectedElements.length > 0) {
        menuSelectedElements.forEach(item => {
          item.setAttribute('data-sport', leagueValue)
        })
      }
    }

    if (IS_VERTICAL) {
      const leagueFallbackClass = `.${LEAGUE_FALLBACK_ID_V2}_${leagueValue}`

      const leagueFallback = document.querySelector(leagueFallbackClass)
      let nodeList = document.querySelectorAll(SPORTS_LIST_V2)

      let menuElements = document.querySelectorAll('.m-yss__vs__list-id li')
      menuElements = [...menuElements]
      const menuElement = document.querySelector('.m-yss__vs__dropdown span')
      for (let index = 0; index < menuElements.length; index++) {
        if (menuElements?.[index]?.dataset?.value?.toLowerCase() === OLYMPIC_LEAGUE) {
          if (menuElement) {
            menuElement.classList.add('has-m-yss__vs-olympic')
          }
        }
      }

      const olympicElement = document.querySelector('.m-yss_vs--olympic')
      const verticalFooterElement = document.querySelector('.m-yss__vs--footer')
      const verticalHeaderTitleElement = document.querySelector('.m-yss__vs--title')
      if (olympicElement) {
        olympicElement.classList.remove('show')
      }
      if (verticalHeaderTitleElement) {
        verticalHeaderTitleElement.innerText = 'Scoreboard'
      }
      if (verticalFooterElement) {
        verticalFooterElement.classList.add('show')
      }

      if (leagueValue.toLowerCase() === OLYMPIC_LEAGUE) {
        if (olympicElement) {
          olympicElement.classList.add('show')
        }
        if (verticalHeaderTitleElement) {
          verticalHeaderTitleElement.innerText = 'Medal Count'
        }
        if (verticalFooterElement) {
          verticalFooterElement.classList.remove('show')
        }
      }

      const leagueFallbackElements = document.querySelectorAll('.m-yss__vs_league__fallback')
      leagueFallbackElements.forEach(item => {
        item.classList.remove('league_active')
      })

      nodeList = [...nodeList]

      let listIndex = 0
      let fallback = true
      nodeList.forEach(item => {
        const {
          dataset: { gameId }
        } = item
        if (gameId) {
          item.classList.add('inVisible')
          if (gameId.toLowerCase().startsWith(leagueValue.toLowerCase())) {
            listIndex += 1
            if (listIndex < 5) {
              item.classList.remove('inVisible')
              fallback = false
            }
          }
        }
      })
      if (fallback && leagueFallback) {
        leagueFallback.classList.add('league_active')
      }
    }

    if (
      (checkForHorizontalScoreboard() || isLeagueAttachedWithGliderForSportPage) &&
      IS_HORIZONTAL
    ) {
      let nodeListH = document.querySelectorAll(SPORTS_LIST_V2H)
      nodeListH = [...nodeListH]
      let countSlidesOfSelectedLeague = 0
      nodeListH.forEach(item => {
        const {
          dataset: { sport }
        } = item
        if (sport) {
          item.classList.add('inVisible')
          if (sport.toLowerCase().startsWith(leagueValue.toLowerCase())) {
            if (item.classList.contains(`m-yss__h_league_type_${leagueValue?.toLowerCase()}`)) {
              if (!item.classList.contains(SLIDER_OPTION.SPORT_TITLE_WRAP)) {
                item.classList.remove('inVisible')
                countSlidesOfSelectedLeague += 1
              }
              if (
                defaultValue === OLYMPIC_LEAGUE &&
                item.classList.contains(SLIDER_OPTION.OLYMPIC_MEDAL_TITLE_WRAP)
              ) {
                item.classList.remove('inVisible')
                countSlidesOfSelectedLeague += 1
              }
            }
          }

          if (isLeagueAttachedWithGliderForSportPage() && defaultValue === ALL_LEAGUE) {
            item.classList.remove('inVisible')
            countSlidesOfSelectedLeague = nodeListH.length
          }
        }
      })
      this.disabledRightArrowOfGlide(countSlidesOfSelectedLeague)
    }
  }

  disabledRightArrowOfGlide(countSlidesOfSelectedLeague) {
    const glideTrackElement = document.querySelector('.m-yss__h-scores-container .glide__track')
    const scoreStripElement = document.querySelector('.m-yss__h--container')
    const glideTrackWidth = glideTrackElement ? glideTrackElement.clientWidth : 600
    const selectedLeagueWidth =
      SLIDER_OPTION.CURRENT_SELECTED_LEAGUE === OLYMPIC_LEAGUE
        ? SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH
        : SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH
    const actualSlideWidth = countSlidesOfSelectedLeague * selectedLeagueWidth
    if (countSlidesOfSelectedLeague <= 1) {
      hideGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT)
      disabledGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT_V2_H)
    } else if (glideTrackWidth > actualSlideWidth) {
      hideGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT)
      disabledGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT_V2_H)
    }
  }

  updateRightArrowUI() {
    if (IS_HORIZONTAL) {
      let scoresSlides = document.querySelectorAll(SPORTS_LIST_V2H)
      scoresSlides = [...scoresSlides]
      const currTab = checkForHorizontalScoreboard()
        ? document.querySelector('.m-yss_h_menu--select_tabs')
        : document.querySelector('.m-yss_h_menu_with_glide--select_tabs')
      if (currTab) {
        const { sport } = currTab.dataset
        const filterSlides = scoresSlides.filter(item =>
          item.classList.contains(`m-yss__h_league_type_${sport?.toLowerCase()}`)
        )
        let countSlidesOfSelectedLeague = filterSlides.length - 1
        if (isLeagueAttachedWithGliderForSportPage() && sport === ALL_LEAGUE) {
          countSlidesOfSelectedLeague = scoresSlides.length
        }
        this.disabledRightArrowOfGlide(countSlidesOfSelectedLeague)
      }
    }
  }

  showMoreOptions(items, selected_id) {
    if (items[0].classList.contains('visible')) {
      this.closeFilters(items)
    } else {
      this.closeFilters(items)
      items[0].classList.add('visible')
      items[0].parentNode.setAttribute('aria-expanded', 'true')
      items[0].parentNode.style.zIndex = '100'
      if (items[0].parentNode.previousSibling.classList.contains('selected_tag')) {
        items[0].parentNode.previousSibling.classList.add('open')
      }
      const itemsChildNodes = items[0].childNodes
      const defaultLabel = document.getElementById(selected_id)?.dataset?.value

      items._activeTab = defaultLabel
      this.rapidBeacon(items, document.getElementById(selected_id), {
        slk: defaultLabel,
        subsec: defaultLabel
      })

      itemsChildNodes.forEach(child => {
        if (child.dataset.value.toLowerCase() === defaultLabel.toLowerCase()) {
          child.childNodes[0].classList.add('selected')
        } else {
          child.classList.remove('inVisible')
          child.childNodes[0].classList.remove('selected')
        }
      })
    }
  }

  closeFilters(items) {
    items[0].classList.remove('visible')
    items[0].parentNode.setAttribute('aria-expanded', 'false')
    if (items[0].parentNode.previousSibling.classList.contains('selected_tag')) {
      items[0].parentNode.previousSibling.classList.remove('open')
    }
  }

  isElementContainsClass(evt, evt_class) {
    return evt.target.classList.contains(evt_class)
  }

  closeFiltersIfEListContainsTargetID(eList, evt) {
    if (eList && !eList[0].classList.contains(evt.target.id)) {
      this.closeFilters(eList)
    }
  }

  showLeaguesAndGetEList(eList, evt, selectedOptionID) {
    this.closeFiltersIfEListContainsTargetID(eList, evt)
    const newEList = document.getElementsByClassName(evt.target.id)
    this.showMoreOptions(newEList, selectedOptionID)
    return newEList
  }

  getDefaultEList() {
    const elements = document.getElementsByClassName(SELECTED_OPTION_ID_V2H_WITH_GLIDE)
    if (elements && elements.length > 0) {
      return elements
    }
    return null
  }

  onClickEvent(evt) {
    if (this.isElementContainsClass(evt, SELECTED_OPTION_CLASS_V2)) {
      this.eList = this.showLeaguesAndGetEList(this.eList, evt, SELECTED_OPTION_ID_V2)
    } else if (
      this.isElementContainsClass(evt, SELECTED_OPTION_CLASS_V2H) &&
      checkForHorizontalScoreboard()
    ) {
      this.eList = this.showLeaguesAndGetEList(this.eList, evt, SELECTED_OPTION_ID_V2H)
    } else if (
      this.isElementContainsClass(evt, SELECTED_OPTION_CLASS_V2H_WITH_GLIDE) &&
      isLeagueAttachedWithGliderForSportPage()
    ) {
      this.eList = this.showLeaguesAndGetEList(this.eList, evt, SELECTED_OPTION_ID_V2H_WITH_GLIDE)
    } else if (
      this.eList &&
      (this.isElementContainsClass(evt, 'm-yss__vs__dropdown--list_label') ||
        this.isElementContainsClass(evt, 'm-yss_h_menu__dropdown--list_label') ||
        this.isElementContainsClass(evt, 'm-yss_h_menu_with_glide__dropdown--list_label') ||
        this.isElementContainsClass(evt, 'm-yss__vs__dropdown--list_off_season') ||
        this.isElementContainsClass(evt, 'm-yss_h_menu__dropdown--list_off_season') ||
        this.isElementContainsClass(evt, 'm-yss_h_menu_with_glide__dropdown--list_off_season'))
    ) {
      const { value } = evt.target.dataset
      this.closeFilters(this.eList)
      evt._activeTab = value
      this.rapidBeacon(evt, evt.target)
      this.sportsWrapToggle_V2_H(evt.target)
    } else if (this.eList) {
      if (
        this.isElementContainsClass(evt, 'm-yss__h-score-strip') ||
        this.isElementContainsClass(evt, 'm-yss__h-score-strip_olympic')
      ) {
        this.setSliderWidthToUI(this.translateWidthValue)
      }
      this.closeFilters(this.eList)
    }
  }

  init(elem) {
    this.scoresMenuToggle(elem)
    this.sportsMenuToggle_V2_H(elem)
    this.initialiseSlider(elem, SLIDER_OPTION)
  }

  scoresMenuToggle(elem) {
    const tabs = elem.querySelectorAll(SCORES_TITLE)
    tabs.forEach(tab =>
      tab.addEventListener('click', ({ target }) => {
        if (!target.parentElement.classList.contains('selected')) {
          this.scoresMenuClickHandler(elem, target)
          this.scoresWrapToggle(elem, target)
          this.glide.go('<<')
        }
      })
    )
  }

  scoresMenuClickHandler(elem, target) {
    const currTab = elem.querySelector(SCORES_MENU_SELECTED)
    currTab.className = ''
    const newTab = target.parentElement
    newTab.className = 'selected'
    const { sport } = newTab.dataset
    elem._activeTab = sport
    this.rapidBeacon(elem, newTab)
  }

  scoresWrapToggle(elem, target) {
    const selectedTab = target.parentElement.dataset.sport
    const scoresSlides = elem.querySelectorAll(SCORE_SLIDE_DATA_SPORT)

    scoresSlides.forEach(scoresSlide => {
      if (selectedTab !== 'all') {
        if (scoresSlide.dataset.sport !== selectedTab) {
          scoresSlide.style.display = 'none'
        } else {
          scoresSlide.style.display = 'table'
        }
      } else {
        scoresSlide.style.display = 'table'
      }
    })
  }

  resizeEventHandler() {
    this.syncSportsUI()
    if (IS_HORIZONTAL) {
      this.glide.mount({ setCustomSlideWidth: this.setCustomSlideWidth })
      const scoreStripElement = document.querySelector('.m-yss__h--container')
      if (isHorizontalForSportPage()) {
        if (!SLIDER_OPTION.mWeb) {
          this.sliderTranslateWidth(0)
          SLIDER_OPTION.mWeb = true
        }
      }
      if (!isHorizontalForSportPage()) {
        SLIDER_OPTION.mWeb = false
      }

      if (SLIDER_OPTION.customMWebScrollIndex) {
        this.sliderTranslateWidth(SLIDER_OPTION.customMWebScrollIndex)
        if (SLIDER_OPTION.customMWebScrollIndex > 0) {
          showGlideArrow(scoreStripElement, GLIDE_ARROW_LEFT)
          enabledGlideArrow(scoreStripElement, GLIDE_ARROW_LEFT_V2_H)
        }
      } else {
        this.sliderTranslateWidth(0)
      }
      this.updateRightArrowUI()
    }
  }

  syncSportsUI() {
    if (IS_HORIZONTAL) {
      let nodeListH = document.querySelectorAll(SPORTS_LIST_V2H)
      nodeListH = [...nodeListH]
      nodeListH.forEach(item => {
        const {
          dataset: { sport }
        } = item
        if (sport) {
          item.classList.remove('inVisible')
        }
      })
      this.applyHorizontalDefaultFilters()
    }
  }

  setCustomSlideWidth(_Glide, Components, Events) {
    return {
      mount() {
        if (IS_HORIZONTAL) {
          customLeagueFallbackWidth()
          const { slides } = Components.Html
          slides.forEach((slide, index) => {
            if (slide.classList.contains(SLIDER_OPTION.SCORE_FALLBACK)) {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`

              const Leagues = document.querySelectorAll(SLIDER_OPTION.LEAGUE_FALLBACK_CLASS)
              if (Leagues && Leagues.length > 0) {
                Leagues.forEach(item => {
                  if (item.id !== LEAGUE_FALLBACK_ID_V2) {
                    item.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
                    item.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH}px`
                  }
                })
              }
            } else if (slide.classList.contains(SLIDER_OPTION.SPORT_TITLE_WRAP)) {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH}px`
            } else if (
              isHorizontalForSportPage() &&
              slide.classList.contains(SLIDER_OPTION.OLYMPIC_SCORE_LEAGUE_WRAP)
            ) {
              slide.style.width = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH}px`
            } else if (slide.classList.contains(SLIDER_OPTION.OLYMPIC_SCORE_LEAGUE_WRAP)) {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH}px`
            } else if (isHorizontalForSportPage()) {
              slide.style.width = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH}px`
            } else {
              slide.style.width = `${SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH}px`
              slide.style['min-width'] = `${SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH}px`
            }
          })
        }
      }
    }
  }

  sliderTranslateWidth(nextIndex) {
    if (nextIndex === 0) {
      SLIDER_OPTION.customMWebScrollIndex = 0
      this.glide.go('<<')
      this.setSliderZeroWidthToUI()
      return
    }

    SLIDER_OPTION.mWeb = false
    let mTranslateWidth = nextIndex * SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH
    if (isHorizontalForSportPage()) {
      mTranslateWidth = nextIndex * SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_SCORE_WIDTH
      if (SLIDER_OPTION.CURRENT_SELECTED_LEAGUE === OLYMPIC_LEAGUE) {
        mTranslateWidth =
          SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH +
          nextIndex * SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH
      }
      SLIDER_OPTION.mWeb = true
    } else if (!SLIDER_OPTION.mWeb && SLIDER_OPTION.CURRENT_SELECTED_LEAGUE === OLYMPIC_LEAGUE) {
      const { actualTranslateWidth, actualIndex, count: titleCount } = this.getSliderWidth(
        nextIndex
      )
      mTranslateWidth = actualTranslateWidth
      SLIDER_OPTION.customMWebScrollIndex = actualIndex
      setRightArrowGradient(titleCount)
      this.glide.go(`=${SLIDER_OPTION.customMWebScrollIndex}`)
    }

    if (SLIDER_OPTION?.isAll) {
      const { actualTranslateWidth, actualIndex, count: titleCount } = this.getSliderWidth(
        nextIndex
      )
      mTranslateWidth = actualTranslateWidth
      SLIDER_OPTION.customMWebScrollIndex = actualIndex
      setRightArrowGradient(titleCount)
      this.glide.go(`=${SLIDER_OPTION.customMWebScrollIndex}`)
    } else {
      setDefaultGradient(0)
    }
    this.setSliderWidthToUI(mTranslateWidth)
  }

  getSliderWidth(nextIndex) {
    const scoresSlides = document.querySelectorAll('.score-slide')
    let actualIndex = nextIndex
    let count = 0
    let fbCount = 0
    let olympicCount = 0
    for (let index = 0; index < nextIndex; index++) {
      if (scoresSlides?.[index]) {
        count = scoresSlides[index]?.classList.contains(SLIDER_OPTION.SPORT_TITLE_WRAP)
          ? count + 1
          : count
        fbCount = scoresSlides[index]?.classList.contains(SLIDER_OPTION.SCORE_FALLBACK)
          ? fbCount + 1
          : fbCount
        olympicCount = scoresSlides[index]?.classList.contains(
          SLIDER_OPTION.OLYMPIC_SCORE_LEAGUE_WRAP
        )
          ? olympicCount + 1
          : olympicCount
      } else {
        actualIndex = index - 1
        break
      }
    }

    let actualTranslateWidth =
      olympicCount * SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH +
      (actualIndex - count - fbCount - olympicCount) * SLIDER_OPTION.CUSTOM_LEAGUE_SCORE_WIDTH +
      count * SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH +
      fbCount * SLIDER_OPTION.CUSTOM_LEAGUE_FALLBACK_WIDTH

    if (SLIDER_OPTION.CURRENT_SELECTED_LEAGUE === OLYMPIC_LEAGUE) {
      actualTranslateWidth = SLIDER_OPTION.CUSTOM_LEAGUE_NAME_WIDTH
      if (isHorizontalForSportPage()) {
        actualTranslateWidth += olympicCount * SLIDER_OPTION.MWEB_CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH
      } else {
        actualTranslateWidth += olympicCount * SLIDER_OPTION.CUSTOM_LEAGUE_OLYMPIC_SCORE_WIDTH
      }
    }

    return { actualTranslateWidth, actualIndex, count }
  }

  setSliderWidthToUI(width) {
    this.translateWidthValue = width
    document.querySelector('.scores-wrap').style.transform = `translate3d(-${width}px, 0px, 0px)`
  }

  setSliderZeroWidthToUI() {
    this.translateWidthValue = 0
    document.querySelector('.scores-wrap').style.transform = `translate3d(0px, 0px, 0px)`
    setDefaultGradient(0)
  }

  updateCustomSlide(nextIndex) {
    if (IS_HORIZONTAL) {
      this.sliderTranslateWidth(nextIndex)
    }
  }

  glideConfig() {
    return new Promise((resolve, reject) => {
      const currentIndex = this.glide.index
      const { perView } = this.glide.settings
      resolve({ perView, currentIndex })
    })
  }

  moveSliderRight() {
    if (IS_HORIZONTAL) {
      this.glideConfig().then(response => {
        const { perView, currentIndex } = response
        let scoresSlides = document.querySelectorAll('.score-slide')
        scoresSlides = [...scoresSlides]
        let nextIndex = currentIndex + perView
        let flagToDisabledRightArrow = false
        if (IS_HORIZONTAL) {
          const currTab = checkForHorizontalScoreboard()
            ? document.querySelector('.m-yss_h_menu--select_tabs')
            : document.querySelector('.m-yss_h_menu_with_glide--select_tabs')
          if (currTab) {
            const { sport } = currTab.dataset
            SLIDER_OPTION.isAll = sport === ALL_LEAGUE
            const filterSlides = scoresSlides.filter(item =>
              item.classList.contains(`m-yss__h_league_type_${sport?.toLowerCase()}`)
            )
            const stripLength = sport === ALL_LEAGUE ? scoresSlides.length : filterSlides.length
            if (nextIndex >= stripLength - perView - 1) {
              nextIndex = stripLength - perView
              if (SLIDER_OPTION.CURRENT_SELECTED_LEAGUE === OLYMPIC_LEAGUE) {
                nextIndex -= 1
              }
              if (nextIndex < 0) {
                nextIndex = 0
              }
              flagToDisabledRightArrow = true
            }
            SLIDER_OPTION.customMWebScrollIndex = nextIndex
          }
        } else if (currentIndex >= scoresSlides.length) {
          nextIndex = currentIndex
        } else if (nextIndex >= scoresSlides.length) {
          nextIndex = currentIndex + 1
        }
        this.updateCustomSlide(nextIndex)
        if (flagToDisabledRightArrow) {
          const scoreStripElement = document.querySelector('.m-yss__h--container')
          hideGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT)
          disabledGlideArrow(scoreStripElement, GLIDE_ARROW_RIGHT_V2_H)
        }
      })
    }
  }

  moveSliderLeft() {
    if (IS_HORIZONTAL) {
      this.glideConfig().then(response => {
        const { perView, currentIndex } = response
        let scoresSlides = document.querySelectorAll('.score-slide')
        scoresSlides = [...scoresSlides]
        let nextIndex = currentIndex - perView
        nextIndex = nextIndex <= 0 ? 0 : nextIndex

        if (IS_HORIZONTAL) {
          const currTab = checkForHorizontalScoreboard()
            ? document.querySelector('.m-yss_h_menu--select_tabs')
            : document.querySelector('.m-yss_h_menu_with_glide--select_tabs')
          if (currTab) {
            const { sport } = currTab.dataset
            SLIDER_OPTION.isAll = sport === ALL_LEAGUE
            const filterSlides = scoresSlides.filter(item =>
              item.classList.contains(`m-yss__h_league_type_${sport?.toLowerCase()}`)
            )

            const stripLength = sport === ALL_LEAGUE ? scoresSlides.length : filterSlides.length
            if (nextIndex >= stripLength - perView - 1) {
              nextIndex = stripLength - perView
            }
            nextIndex -= perView
            if (SLIDER_OPTION.customMWebScrollIndex) {
              nextIndex = SLIDER_OPTION.customMWebScrollIndex - perView
              SLIDER_OPTION.customMWebScrollIndex = nextIndex <= 0 ? 0 : nextIndex
            }
            nextIndex = nextIndex <= 0 ? 0 : nextIndex
          }
        }
        nextIndex <= 0 ? this.glide.go('<<') : this.glide.go(`=${nextIndex + 1}`)
        this.updateCustomSlide(nextIndex)
      })
    }
  }

  initialiseSlider(elem, config) {
    this.glide = new Glide(elem.querySelector(GLIDE), config)

    this.glide.on(['mount.after', 'run.start'], () => {
      hideGlideArrow(elem, GLIDE_ARROW_LEFT)
      disabledGlideArrow(elem, GLIDE_ARROW_LEFT_V2_H)
    })
    this.glide.on('run.end', () => {
      hideGlideArrow(elem, GLIDE_ARROW_RIGHT)
      disabledGlideArrow(elem, GLIDE_ARROW_RIGHT_V2_H)
    })
    this.glide.on('run.before', evt => {
      showGlideArrow(elem, GLIDE_ARROW_LEFT)
      showGlideArrow(elem, GLIDE_ARROW_RIGHT)
      enabledGlideArrow(elem, GLIDE_ARROW_LEFT_V2_H)
      enabledGlideArrow(elem, GLIDE_ARROW_RIGHT_V2_H)
      if (IS_HORIZONTAL) {
        const { direction, steps } = evt
        if (direction === '|') {
          if (steps === '<') {
            this.moveSliderLeft()
          } else if (steps === '>') {
            this.moveSliderRight()
          }
        }
      }
    })
    const rapidMod = elem.closest('[data-rapid=true]')
    this.glide.on(['run.after'], evt => {
      rapidReady(rapid => {
        rapid.refreshModule(rapidMod.id)
      })
    })
    this.glide.on(
      'resize',
      debounceForSportUI(e => {
        this.resizeEventHandler()
      }, 200)
    )

    const leftArrow = elem.querySelector(GLIDE_ARROW_LEFT)
    if (leftArrow) {
      leftArrow.addEventListener('click', () => {
        elem._activeTab = this.getSelectedTabValue()
        this.rapidBeacon(elem, leftArrow)
        this.moveSliderLeft()
      })
    }

    const rightArrow = elem.querySelector(GLIDE_ARROW_RIGHT)
    if (rightArrow) {
      rightArrow.addEventListener('click', () => {
        elem._activeTab = this.getSelectedTabValue()
        this.rapidBeacon(elem, rightArrow)
        this.moveSliderRight()
      })
    }

    const scoreCards = [...elem.querySelectorAll('.score')]
    scoreCards.forEach(scoreCard => {
      scoreCard.addEventListener('click', () => {
        this.rapidBeacon(elem, scoreCard)
      })
    })

    this.glide.mount({ setCustomSlideWidth: this.setCustomSlideWidth })
  }

  getSelectedTabValue() {
    try {
      const currTab = document.getElementById(SELECTED_OPTION_ID_V2H_WITH_GLIDE)
      const value = currTab?.dataset?.value || 'all'
      if (value?.toLowerCase() === 'all leagues') {
        return 'all'
      }
      return value
    } catch (err) {
      _sportsLogError(err)
      return 'all'
    }
  }

  rapidBeacon(elem, target, overridesYlk = {}) {
    const tab = elem._activeTab || 'all'
    const { ylk } = target.dataset
    let ylkObj = ylkStringToObj(ylk)
    ylkObj.subsec = tab
    ylkObj = { ...ylkObj, ...overridesYlk }
    rapidReady(rapid => {
      rapid.beaconClick(ylkObj.sec, ylkObj.slk, ylkObj.itc, ylkObj)
    })
  }

  sportsMenuToggle_V2_H(elem) {
    const tabs = elem.querySelectorAll(SPORTS_LABEL_V2_H)
    tabs.forEach(tab =>
      tab.addEventListener('click', ({ target }) => {
        this.sportsWrapToggle_V2_H(target)
      })
    )
  }

  sportsWrapToggle_V2_H(target) {
    const selectedPill = target.dataset.sport?.toLowerCase()
    SLIDER_OPTION.isAll = selectedPill === ALL_LEAGUE
    this.syncSportsUI()
    this.applyFilters(selectedPill)
    SLIDER_OPTION.customMWebScrollIndex = 0
    this.glide.go('<<')
    this.sliderTranslateWidth(0)
  }
}
