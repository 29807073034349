const el = require('../../../lib/utils/jsx_component');
module.exports = renderObj => {
  const {
    firstBaseRunnerFlag,
    secondBaseRunnerFlag,
    thirdBaseRunnerFlag,
    displayName,
    out
  } = renderObj;
  const color1 = firstBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  const color2 = secondBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  const color3 = thirdBaseRunnerFlag ? '#0047FF' : '#E0E4E9';
  return el("div", {
    className: "m-yss__vs-mlb"
  }, el("div", null, el("svg", {
    width: "27",
    height: "19",
    viewBox: "0 0 27 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, el("rect", {
    x: "7.86157",
    y: "5.99844",
    width: "8",
    height: "8",
    transform: "rotate(-45.826 7.86157 5.99844)",
    fill: `${color3}`
  }), el("rect", {
    x: "0.538086",
    y: "12.6814",
    width: "8",
    height: "8",
    transform: "rotate(-45.826 0.538086 12.6814)",
    fill: `${color2}`
  }), el("rect", {
    x: "15.0142",
    y: "12.7647",
    width: "8",
    height: "8",
    transform: "rotate(-45.826 15.0142 12.7647)",
    fill: `${color1}`
  }))), el("div", {
    className: "m-yss__vs-mlb-info"
  }, el("div", {
    className: "m-yss__vs-mlb-info-display-name"
  }, `${displayName}`), el("div", {
    className: "m-yss__vs-mlb-info-out"
  }, `${out}`)));
};